import React from 'react'
import { Row, Col } from 'react-bootstrap';

const Tiles = ({catsForList, products, categorySelect}) => {
    return (
        <div>
            <Row>
    {catsForList.map(cat => {
      const catTotal = products.filter(p => p.productType === cat)
      const randomIndex = Math.floor(Math.random() * catTotal.length);
      

    return <Col key={cat} sm={6} md={3} ><div 
    onClick={() => categorySelect(cat)}
    className="tile"
    style={{backgroundImage: `url('${catTotal[randomIndex].images[0].src}')`}}
    >
     <div className="tile-text" > 
     <h5>{cat}</h5>
     <div className="tile-button" >
        <span >Show all {catTotal.length} {cat} >></span>
     </div>

     </div>

    </div>
</Col>


    } )}
  
    </Row>
        </div>
    )
}

export default Tiles
