import React from "react";
import { Row, Col } from 'react-bootstrap';

const ProductSearchBar = ({products, showFeatured, setFilteredProducts, handleSearchInput, setTagFilter, setFilter }) => {
 
  const catsInUse = products.map(p => p.productType)
const catsForList = [...new Set(catsInUse)]
console.log(catsForList);
  
 
 
  return (
    <>
      <div className="product-search-bar">
        <Row>
          <Col md={4} className="text-center" >
        <button
        style={{marginTop:10}}
        type="button"
          className="btn btn-warning"
         onClick={() => setFilteredProducts([])}
        >
        Home        
        </button>
        </Col>
        <Col md={4} className="text-center">
        <div style={{marginTop:10}} >
        <select className="select" onChange={setFilter}>
          <option value="all">Search by Department</option>
          {catsForList.map(cat => <option key={cat} value={cat} >{cat}</option> )}
        </select>
        </div>
        </Col>
        <Col md={4} className="text-center">
      <div>
       {/* <span style={{marginRight:10}} > <FaSearch size={25}/></span> */}
        <input
          className="search-input"
          placeholder="start typing to search..."
          type="text"
          onChange={handleSearchInput}
        />
      </div>
      </Col>
      </Row>
      </div>

      
    </>
  );
};

export default ProductSearchBar;
