import React, { useState, useEffect, createContext } from "react";
import Client from "shopify-buy";

const ShopContext = createContext(null);

const client = Client.buildClient({
  domain: "ninja-panther.myshopify.com",
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY,
});

const ShopProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [basket, setBasket] = useState({});
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [numberOfItems, setNumberOfItems] = useState(0);
  const [collections, setCollections] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    createCheckout();
  }, []);

  const createCheckout = async () => {
    const checkout = await client.checkout.create();
    // localStorage.setItem('checkout', checkout.id)
    setBasket(checkout);
    console.log(checkout);
  };

  const fetchCheckout = async (checkoutId) => {
    client.checkout
      .fetch(checkoutId)
      .then((checkout) => {
        console.log(checkout);
        setBasket(checkout);
      })
      .catch((err) => console.log(err));
  };

  const addItemToCart = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    const checkout = await client.checkout.addLineItems(
      basket.id,
      lineItemsToAdd
    );
    setBasket(checkout);
    setNumberOfItems(numberOfItems + 1);
    console.log(numberOfItems);
    console.log(checkout);
  };

  const removeLineItemInCart = async (lineItemId) => {
    const checkout = await client.checkout
      .removeLineItems(basket.id, lineItemId)
      .then((res) => {
        //  console.log(res)
        setBasket(res);
        setNumberOfItems(numberOfItems - 1);
      });
    console.log(checkout);
  };

  const updateQuantity = async (lineItemId, quantity) => {
    const checkoutId = basket.id;
    const lineItemsToUpdate = [{ id: lineItemId, quantity: +quantity }];

    return await client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then((res) => {
        setBasket(res);
        console.log(lineItemsToUpdate);
      });
  };

  const decrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity - 1;
    updateQuantity(itemId, updatedQuantity);
    console.log(lineItemId.quantity);
    setNumberOfItems(numberOfItems - 1);
    console.log(numberOfItems);
  };

  const incrementQuantity = (lineItemId) => {
    const itemId = lineItemId.id;
    const updatedQuantity = lineItemId.quantity + 1;
    updateQuantity(itemId, updatedQuantity);
    setNumberOfItems(numberOfItems + 1);
    console.log(numberOfItems);
  };

  const fetchProducts = async () => {
    const products = await client.product.fetchAll(250);
    setProducts(products);
    // setFilteredProducts(products)

    console.log(products);
  };

  const [relatedProducts, setRelatedProducts] = useState([]);

  const fetchSingle = async (id) => {
    const single = await client.product.fetch(id);
    console.log(single);
    setProduct(single);
    const cat = single.productType;
    const relatedArray = products.filter((p) => p.productType === cat);
    setRelatedProducts(relatedArray);
    console.log(relatedArray);
  };

  const [featuredProducts, setFeaturedProducts] = useState([]);
  const getFeatured = async () => {
    const data = await client.product.fetchQuery({ query: `tag:[Featured]` });
    setFeaturedProducts(data);
    console.log(data);
  };

  const fetchQuery = async (query) => {
    const data = await client.product.fetchQuery(query);
    // setProducts(data)
    setFilteredProducts(data);
    console.log(data);
  };

  const handleOptionChange = async (event) => {
    const target = event.target;
    let chosenOptions = selectedOptions;
    chosenOptions[target.name] = target.value;

    // const selectedVariant = await client.product.helpers.variantForOptions(this.state.product, selectedOptions)
    console.log(target.value);
    // this.setState({
    //   selectedVariant: selectedVariant,
    // });
  };
  // const fetchCollections = async () => {
  //   // Fetch all collections, including their products
  // const collections = await client.collection.fetchAllWithProducts()

  //   console.log(collections[1].attrs);
  // this.setState({collections: collections[1].products})
  // }

  const closeCart = () => {
    setIsCartOpen(false);
  };

  const openCart = () => {
    setIsCartOpen(true);
  };

  return (
    <ShopContext.Provider
      value={{
        featuredProducts,
        getFeatured,
        isCartOpen,
        basket,
        products,
        product,
        setProduct,
        fetchCheckout,
        fetchProducts,
        fetchSingle,
        closeCart,
        openCart,
        addItemToCart,
        removeLineItemInCart,
        updateQuantity,
        decrementQuantity,
        incrementQuantity,
        handleOptionChange,
        // fetchCollections,
        fetchQuery,
        collections,
        numberOfItems,
        filteredProducts,
        setFilteredProducts,
        relatedProducts,
        setRelatedProducts,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;
