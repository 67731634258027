import React, { useContext } from "react";
import { ShopContext } from "../context/ShopContext";
import { Div, SideDrawer, Text, Row, Col } from "atomize";
import { Link } from "react-router-dom";
import { FaArrowCircleRight } from "react-icons/fa";
import logo from "../assets/imgs/logo2.png";

const Cart = () => {
  const {
    isCartOpen,
    closeCart,
    basket,
    removeLineItemInCart,
    decrementQuantity,
    incrementQuantity,
    numberOfItems,
  } = useContext(ShopContext);

  return (
    <SideDrawer isOpen={isCartOpen} onClose={closeCart}>
      <div className='cart-logo-wrapper'>
        <img src={logo} alt='' style={{ width: "65%" }} />
      </div>
      {numberOfItems !== 0 ? (
        <Div className='cart' d='flex' flexDir='column' m={{ b: "4rem" }}>
          {basket.lineItems &&
            basket.lineItems.map((item) => (
              <Row className='cart-item' key={item.id}>
                <Col>
                  <Div
                    className='cart-img'
                    bgImg={item.variant.image.src}
                    bgSize='cover'
                    bgPos='center center'
                    h='5rem'
                    w='4rem'
                  />
                  <small
                    className='cart-remove'
                    onClick={() => removeLineItemInCart(item.id)}
                  >
                    remove
                  </small>
                </Col>
                <Col>
                  <Text>{item.title}</Text>
                  <Text>{item.variant.title}</Text>
                  <div className='Line-item__quantity-container'>
                    <button
                      className='quantity-button'
                      onClick={() => decrementQuantity(item)}
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button
                      className='quantity-button'
                      onClick={() => incrementQuantity(item)}
                    >
                      +
                    </button>
                  </div>
                </Col>
                <Col>
                  <Text>£{item.variant.price.amount}</Text>
                </Col>
              </Row>
            ))}
          <p>£{basket.totalPrice.amount}</p>

          <a className='btn btn-warning' href={basket.webUrl} target='_blank'>
            Checkout
          </a>
          <button className='btn btn-light' onClick={closeCart}>
            Continue Shopping
          </button>
        </Div>
      ) : (
        <div className='empty-cart'>
          <h4>Your cart is empty</h4>
          <p>Time to go shopping!</p>
          <Link onClick={closeCart} className='btn btn-dark' to='/'>
            Start shopping <FaArrowCircleRight />{" "}
          </Link>
        </div>
      )}
    </SideDrawer>
  );
};

export default Cart;
