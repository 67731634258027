import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ShopContext } from "../context/ShopContext";
import { Row, Col } from "react-bootstrap";
import { FaShoppingCart, FaArrowLeft } from "react-icons/fa";
import marked from "marked";

const Product = () => {
  const {
    fetchSingle,
    addItemToCart,
    setFilteredProducts,
    relatedProducts,
    product,
    setProduct,
    openCart,
  } = useContext(ShopContext);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedImage, setSelectedImage] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = window.location.pathname.split("product/")[1];
    fetchSingle(id);
  }, []);

  if (!product) {
    return <h3>Loading...</h3>;
  }

  const optionSelect = (event) => {
    if (event.target.value === "none") {
      setSelectedOption(null);
    } else {
      setSelectedOption(event.target.value);
    }
  };

  let desc;
  if (product.description) desc = marked(product.descriptionHtml);

  return (
    <div style={{ padding: 20 }}>
      <Link
        to='/'
        onClick={() => {
          setProduct({});
          setFilteredProducts([]);
        }}
        className='btn btn-warning'
      >
        <FaArrowLeft /> Home
      </Link>
      <button className='btn btn-success' onClick={openCart}>
        Show cart <FaShoppingCart />
      </button>
      <h3 style={{ marginTop: 35, fontWeight: 300 }}>{product.title}</h3>
      <p>in "{product.productType}"</p>
      <hr />

      <Row style={{ marginTop: 25 }}>
        <Col xs={12} md={2}>
          <Row>
            {product.images &&
              product.images.map((image) => {
                return (
                  <Col xs={4} md={12}>
                    {" "}
                    <div
                      style={{
                        maxHeight: 190,
                        overflow: "hidden",
                        marginBottom: 10,
                        cursor: "pointer",
                      }}
                    >
                      <img
                        alt=''
                        onMouseOver={() => setSelectedImage(image.src)}
                        src={image.src}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </Col>
                );
              })}
          </Row>
        </Col>
        <Col md={1} xs={0}></Col>
        <Col md={8} xs={12}>
          {product.images && selectedImage && (
            <div style={{ height: 500, objectFit: "contain" }}>
              <img
                alt=''
                src={selectedImage}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
          )}
          {product.images && !selectedImage && (
            <div style={{ objectFit: "contain" }}>
              <img
                alt=''
                src={product.images[0].src}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
              />
            </div>
          )}
        </Col>
      </Row>
      <hr />
      <div>
        <div
          style={{
            marginTop: 45,
            display: "flex",
            flexDirection: "column",
            width: "70%",
            alignItems: "start",
          }}
        >
          {product.variants && (
            <>
              <h5>£{product.variants[0].price.amount}</h5>

              {product.variants.length === 1 && (
                <button
                  onClick={() => {
                    addItemToCart(product.variants[0].id, 1);
                    openCart();
                  }}
                  className='btn btn-success'
                >
                  Add to Cart
                </button>
              )}

              {product.variants.length > 1 && (
                <select onChange={optionSelect}>
                  <option value='none'>Please choose an option</option>
                  {product.variants.map((option) => (
                    <option value={option.id}>
                      {option.title} : £{option.price.amount}
                    </option>
                  ))}{" "}
                </select>
              )}
            </>
          )}
          {selectedOption && (
            <button
              style={{ marginTop: "15px" }}
              className='btn btn-success'
              onClick={() => {
                addItemToCart(selectedOption, 1);
                openCart();
              }}
            >
              Add to Cart
            </button>
          )}
        </div>{" "}
      </div>
      <div style={{ marginTop: 45 }}>
        <section
          style={{ textAlign: "justify", padding: 25 }}
          dangerouslySetInnerHTML={{ __html: desc }}
        />

        {relatedProducts.length > 0 && (
          <>
            <h4>Related Products</h4>
            <div className='related-products-row'>
              {relatedProducts.map((product) => (
                <div
                  onClick={() => {
                    setProduct(product);
                    window.scrollTo(0, 0);
                  }}
                  className='related-product-card'
                >
                  <p>{product.title}</p>
                  <img src={product.images[0].src} alt='' />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Product;
