import React, { useState, useEffect, useContext } from "react";
import "../styles/styles.css";

import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import { ShopContext } from "../context/ShopContext";
import { FaStar } from "react-icons/fa";
import ProductSearchBar from "./ProductSearchBar";
import Client from "shopify-buy";
import Tiles from "./Tiles";

const client = Client.buildClient({
  domain: "ninja-panther.myshopify.com",
  storefrontAccessToken: process.env.REACT_APP_SHOPIFY,
});

const Main = () => {
  const {
    getFeatured,
    featuredProducts,
    fetchProducts,
    filteredProducts,
    setFilteredProducts,
    products,
    fetchQuery,
  } = useContext(ShopContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [merch, setMerch] = useState([]);

  const getMerch = async () => {
    let query = { query: `tag:["Movie Merchandise"]` };
    const data = await client.product.fetchQuery(query);
    console.log(data);

    setMerch(data);
  };

  useEffect(() => {
    fetchProducts();
    getFeatured();
    getMerch();

    return () => {};
  }, []);

  const catsInUse = products.map((p) => p.productType);
  const catsForList = [...new Set(catsInUse)];

  const handleSearchInput = (e) => {
    const array = [...products];

    setSearchTerm(e.target.value.toLowerCase());
    if (e.target.value === "") {
      setFilteredProducts(array);
    } else {
      const filtered = array.filter(
        (p) =>
          p.title.toLowerCase().includes(searchTerm) ||
          p.productType.toLowerCase().includes(searchTerm) ||
          p.description.toLowerCase().includes(searchTerm)
      );
      setFilteredProducts(filtered);
      console.log(filtered);
    }
  };

  const setTagFilter = (event) => {
    if (event.target.value === "all") {
      fetchProducts();
    } else {
      let query = { query: `tag:[${event.target.value}]` };
      fetchQuery(query);
    }
  };

  const categorySelect = (cat) => {
    const array = [...products];
    const filtered = array.filter((p) => p.productType === cat);
    setFilteredProducts(filtered);
    console.log(filtered);
    setSearchTerm(cat);
  };

  const setFilter = (e) => {
    const tag = e.target.value;
    console.log(tag);

    const array = [...products];
    const filtered = array.filter((p) => p.productType === tag);
    setFilteredProducts(filtered);
    console.log(filtered);
    setSearchTerm(tag);

    if (tag === "all") {
      setFilteredProducts(products);
      setSearchTerm("");
    }
  };

  return (
    <div>
      <ProductSearchBar
        setFilteredProducts={setFilteredProducts}
        products={products}
        showFeatured={() => setFilteredProducts([])}
        setTagFilter={setTagFilter}
        setFilter={setFilter}
        handleSearchInput={handleSearchInput}
      />
      <div className='search-results-bar'>
        {filteredProducts.length > 0 && (
          <span>
            Showing {filteredProducts.length} items for <em>"{searchTerm}"</em>
          </span>
        )}
      </div>

      <div className='home-features'>
        {/* <h1 style={{textAlign:'left'}}>ADD TILES FOR SEARCHING/FILTERS LIKE OSSIA</h1> */}
        {filteredProducts.length === 0 && (
          <div>
            <h3>Featured Products</h3>

            <div className='product-list'>
              {featuredProducts.map((product) => {
                return (
                  <div className='product-card'>
                    <Link to={"/product/" + product.id}>
                      <div>
                        <img src={product.images[0].src} alt='' />
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
            <Tiles
              products={products}
              categorySelect={categorySelect}
              catsForList={catsForList}
            />

            {merch.length > 0 && (
              <div className='merch-feature-container'>
                <div className='stars'>
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
                <h4>
                  {/* <FaFilm size={45}/>  */}
                  Fan Merchandise
                </h4>

                <div className='product-list'>
                  {merch.map((product, index) => {
                    return (
                      <div key={index} className='product-card'>
                        <Link to={"/product/" + product.id}>
                          <div>
                            <img src={product.images[0].src} alt='' />
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}

        {filteredProducts.length > 0 && (
          <div className='contents'>
            <Row>
              {filteredProducts.map((product, index) => {
                return (
                  <Col key={index} md={3}>
                    <Link to={`/product/${product.id}`}>
                      <div
                        className='filtered-product-card main-box'
                        style={{ marginBottom: 20 }}
                      >
                        <p style={{ textAlign: "center" }}>{product.title}</p>
                        <hr />
                        <img
                          style={{ maxWidth: "100%" }}
                          src={product.images[0].src}
                          alt=''
                        />
                        <hr />
                        <p>£{product.variants[0].price.amount}</p>
                        <div className='overlay'>
                          <div>
                            <h5>MORE</h5>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
