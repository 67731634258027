import React, { Component } from "react";
import {ShopContext} from '../../context/ShopContext'
import {
  FaShoppingCart

} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Toolbar.css";

import logo from '../../assets/imgs/logo2.png'

class toolbar extends Component {
 

  static contextType = ShopContext;

  render() {
    return (
      <header className="toolbar">
        <div >
            <ul>
              {/* <Link>Contact Us</Link> */}

              <Link to="/">
                <div className="hero" >
              <img src={logo}  />
              <h1>Ninja Panther</h1>
              </div>
                </Link>
              {/* <Link>Online Store</Link> */}
            </ul>
          </div>
        <nav className="toolbar__navigation">
          {/* <div className="toolbar__toggle-button">
            <DrawerToggleButton click={this.props.drawerClickHandler} />
          </div> */}

          
          <div className="spacer" />
          <div className="toolbar_navigation-items">
              <div className="cart-btn-wrapper">
                {" "}
                <FaShoppingCart style={{marginBottom:10}} size={25} onClick={this.context.openCart} />
                <span style={{ marginLeft: "3px" }}>
                  {this.context.numberOfItems}
                </span>
              </div>{" "}
          
          </div>
        </nav>
      </header>
    );
  }
}
export default toolbar;
