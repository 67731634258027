import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import  ShopProvider  from './context/ShopContext' 
import Cart from './components/Cart'

import Main from './components/Main';
import Footer from './components/Footer'
import Product from './pages/Product';
import Toolbar from './components/Toolbar/Toolbar'
import Contact from './components/Contact';


const engine = new Styletron();

const App = () => {

  return (
    <ShopProvider>
    <StyletronProvider value={engine}  >
    <Router>
   <Toolbar/>
    <Cart/>
    <Switch>
    <Route path="/product/:id" component={Product}/>
    <Route exact path="/" component={Main}/>

    </Switch>

  <Contact/>
        <Footer/>
    </Router>
    </StyletronProvider>
    </ShopProvider>
  );
  }

export default App;
