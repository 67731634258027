import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import logo from '../assets/imgs/logo2.png'
import { FaTimesCircle } from 'react-icons/fa'

const Contact = () => {
    const [modalOpen, setModalOpen] = useState(false)
    const [showTnC, setShowTnC] = useState(false)

    return (
        <div className="text-center" style={{padding:30}} >
            <hr/>
            <img src={logo} alt=""  style={{width:200, marginBottom:20}} /><br/>
            <button type="button"  onClick={() => setModalOpen(true)} className="btn btn-warning" >Contact Us</button>
            <div>
                <small>Ninja-Panther Ltd<br/> 8 Stephenson Walk, Fairfield, Hitchin, Herts. SG54GB, UK
</small><br/>
        <small className="t-c" onClick={() => setShowTnC(true) } >Terms & Conditions</small>
        <Modal show={showTnC} >
            <div style={{padding:25}} >
            <FaTimesCircle style={{cursor:'pointer', float:'right'}} onClick={() => setShowTnC(false)} /><br/>
    <div className="text-center" >
            <img src={logo} style={{width:175}} alt="" /><br/>
    <h3>Ninja-Panther Ltd</h3>
    <br/>
<h4>Customer Terms and Conditions</h4>
</div>
<br/>
<div className="terms">
<h6>Delivery Arrangements</h6>
<p>Item location: Hitchin, United Kingdom<br/>
Postage to: United Kingdom<br/>
Postage and packaging determined at check out.<br/>
Standard Delivery (Royal Mail 2nd Class) or equivalent.<br/>
We endeavour to post in a timely manner usually by next working day.
</p>

<h6>Liability for supplementary Costs</h6>
<p>
Determined on a case by case basis. Please contact us.
</p>
<h6>Events outside our control</h6>
<p>
We will not be liable or responsible for any failure to perform, or delay in performance of, any of our obligations that is caused by events outside our reasonable control.
</p>

<h6>Returns policy</h6>
<p>
If your item arrives damaged, doesn’t match the listing description, or is the wrong item, you can return it for a refund including return postage.
</p>
<h6>Information on right to cancel</h6>
<p>
If you've changed your mind and want to send it back, you can ask the seller if they'll accept a return(must be within 30 days and Buyer pays return postage).
</p>
<h6>Get your refund</h6>
<p>
Once the seller receives the item back from you, we have 2 business days to issue your refund. When we do, you'll receive an email letting you know.
It usually takes 3 to 5 business days for a PayPal refund to go through. If you paid with a credit card, it may take up to 30 days to process, depending on your card provider.
</p>
<hr/>
<h6>Website Privacy Policy</h6> 
  
<p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, ninja-panther.co.uk accessible at www.ninja-panther.co.uk.</p>

<p>These Terms will be applied fully and affect to your use of this Website. By using this Website, you agreed to accept all terms and conditions written in here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions. These Terms and Conditions have been generated with the help of the <a href="https://www.termsandcondiitionssample.com">Terms And Conditions Sample</a> and the <a href="https://www.generateprivacypolicy.com">Privacy Policy Generator</a>.</p>

<p>Minors or people below 18 years old are not allowed to use this Website.</p>

<h6>Intellectual Property Rights</h6>

<p>Other than the content you own, under these Terms, Ninja Panther Ltd and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>

<p>You are granted limited license only for purposes of viewing the material contained on this Website.</p>

<h6>Restrictions</h6>

<p>You are specifically restricted from all of the following:</p>
<p>
<ol>
    <li>publishing any Website material in any other media;</li>
    <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
    <li>publicly performing and/or showing any Website material;</li>
    <li>using this Website in any way that is or may be damaging to this Website;</li>
    <li>using this Website in any way that impacts user access to this Website;</li>
    <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
    <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
    <li>using this Website to engage in any advertising or marketing.</li>
</ol>
</p>
<p>Certain areas of this Website are restricted from being access by you and Ninja Panther Ltd may further restrict access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are confidential and you must maintain confidentiality as well.</p>

<h6>Your Content</h6>

<p>In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or other material you choose to display on this Website. By displaying Your Content, you grant Ninja Panther Ltd a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>

<p>Your Content must be your own and must not be invading any third-party’s rights. Ninja Panther Ltd reserves the right to remove any of Your Content from this Website at any time without notice.</p>

<h6>Your Privacy</h6>

<p>Please read Privacy Policy.</p>

<h6>No warranties</h6>

<p>This Website is provided "as is," with all faults, and Ninja Panther Ltd express no representations or warranties, of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</p>

<h6>Limitation of liability</h6>

<p>In no event shall Ninja Panther Ltd, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  Ninja Panther Ltd, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this Website.</p>

<h6>Indemnification</h6>

<p>You hereby indemnify to the fullest extent Ninja Panther Ltd from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

<h6>Severability</h6>

<p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>

<h6>Variation of Terms</h6>

<p>Ninja Panther Ltd is permitted to revise these Terms at any time as it sees fit, and by using this Website you are expected to review these Terms on a regular basis.</p>

<h6>Assignment</h6>

<p>The Ninja Panther Ltd is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>

<h6>Entire Agreement</h6>
    
<p>These Terms constitute the entire agreement between Ninja Panther Ltd and you in relation to your use of this Website, and supersede all prior agreements and understandings.</p>

<h6>Governing Law & Jurisdiction</h6>

<p>These Terms will be governed by and interpreted in accordance with the laws of the State of gb, and you submit to the non-exclusive jurisdiction of the state and federal courts located in gb for the resolution of any disputes.</p>
</div>   
            <button className="btn btn-light" onClick={() => setShowTnC(false)} >close</button>
            </div>

        </Modal>
    </div>
            <Modal show={modalOpen} >
                <div style={{padding:30}} className="text-center">
                    <img src={logo} alt="" style={{width:150}}/>
                    <h4>Contact Us</h4>
                    <p>8 Stephenson Walk, Fairfield, Hitchin, Herts. SG54GB, UK</p>
                    <hr/>
                    <p>Please use the form to send us a message and we will get back to you as soon as we can. </p>
                    <form className="form-group"   
                    action="https://formspree.io/f/xyylgdoj"
                    method="POST" >
                        Please enter your name: 
                        <input className="form-control" name="name" type="text"/>
                        Please enter your email address:
                        <input className="form-control" name="email" type="text"/>
                        Please write your message here:
                        <textarea className="form-control" name="message" id=""  rows={10}></textarea>
                    
                        <button className="btn btn-success" >send</button>
                        <button type="button" onClick={() => setModalOpen(false)} className="btn btn-light" >cancel</button>
                    </form>
                
                </div>

            </Modal>
        </div>
    )
}

export default Contact
